/** @jsx jsx */
import { jsx } from "theme-ui"

import { mediaQueryList } from "../helpers"

import Carousel from "../components/carousel"
import Column from "../components/column"
import Container from "../components/container"
import ProjectCard from "./project-card"
import Row from "../components/row"

import { useIsClient } from "../hooks/use-is-client"
import { useMedia } from "react-use"
import React, { useState, useEffect } from "react"

const FeaturedProjects = ({ data }) => {
  const fullWidthList = [true, true, true]
  const projectList = data.edges || data.contentCards
  const [ready, setReady] = React.useState(false)
  const [isMobile, setWidth] = useState(false);

  const setWindowWidth = () => {
    setWidth((window.innerWidth <= 768));
     
  }

  const checkIfWindow = () => {
    if (typeof window !== "undefined") {
      window.addEventListener("orientationchange", setWindowWidth)
      return typeof window !== "undefined"
    }
    return typeof window !== "undefined"
  }
  
  useEffect(() => {
    if (checkIfWindow) {
      window.addEventListener('resize', setWidth((window.innerWidth <= 768)));
      setReady(true);
    }
  }, []);
  
  const renderProjectCard = (projectIndex, type) => {
    if (data.edges)
      return projectList[projectIndex] ? (
        <ProjectCard
          background={projectList[projectIndex].node.projectImages[0].file.url}
          text={[
            projectList[projectIndex].node.projectTitle.projectTitle,
          ].concat(
            projectList[projectIndex].node.projectLocation.projectLocation
          )}
          link={{
            to: `/projects/${projectList[projectIndex].node.slug}`,
            type: "internal",
          }}
          type={type || "primary"}
        />
      ) : null
    else if (data.contentCards)
      return projectList[projectIndex] ? (
        <ProjectCard
          background={(isMobile && (typeof projectList[projectIndex].mobileImage === 'object' && projectList[projectIndex].mobileImage !== null)) ? projectList[projectIndex].mobileImage.file.url : projectList[projectIndex].desktopImage.file.url}
          text={[projectList[projectIndex].title]}
          link={{
            to: `/projects/${projectList[projectIndex].ctaDestination.ctaDestination}`,
            type: "internal",
          }}
          type={type || "primary"}
        />
      ) : null
  }
  const isClient = useIsClient()
  const mediaQueryMatch = useMedia(mediaQueryList({ mediaPrefix: false })[1])

  let projectsFour = projectList?.length == 4 ? true : false

  return (
    <Container customStyle={{ pb: 5 }} fullWidth={fullWidthList}>
      {isClient && mediaQueryMatch && ready ? (
        <Row noGutters={fullWidthList}>
          <Column size={[12, 12, 6]}>
            <Row>
              <Column customStyle={{ pr: [2, 2, 1], pl: 2 }}>
                {renderProjectCard(0)}
              </Column>
            </Row>
            <Row customStyle={{ mt: 2 }} size={[12, 12, 6]}>
              <Column customStyle={{ mb: [2, 2, 0], pr: [2, 2, 1], pl: 2 }}>
                {renderProjectCard(1, "secondary")}
              </Column>
              {!projectsFour && (
                <Column
                  customStyle={{ pr: [2, 2, 1], pl: [2, 2, 1] }}
                  size={[12, 12, 6]}
                >
                  {renderProjectCard(2, "secondary")}
                </Column>
              )}
            </Row>
          </Column>
          <Column>
            <Row>
              <Column
                customStyle={{
                  mt: [2, 2, 0],
                  mb: [2, 2, 0],
                  pl: 1,
                  pr: [2, 2, 1],
                }}
                size={[12, 12, projectsFour? 12:6]}
              >
                {renderProjectCard(!projectsFour ? 3 : 2, "secondary")}
              </Column>
             {!projectsFour && <Column customStyle={{ pl: 1, pr: 2 }}>
                {renderProjectCard(4, "secondary")}
              </Column>}
            </Row>
            <Row customStyle={{ mt: 2 }}>
              <Column customStyle={{ pl: 1, pr: 2 }}>
                {renderProjectCard(!projectsFour? 5: 3)}
              </Column>
            </Row>
          </Column>
        </Row>
      ) : (ready) && (
        <Row noGutters={fullWidthList}>
          <Column size={[12]}>
            <Carousel type="quaternary">
              {data.edges &&
                projectList.map((project, index) => (
                  <ProjectCard
                    key={index}
                    background={project.node.projectImages[0].file.url}
                    text={[project.node.projectTitle.projectTitle].concat(
                      project.node.projectLocation.projectLocation
                    )}
                    link={{
                      to: `/projects/${project.ctaDestination.ctaDestination}`,
                      type: "internal",
                    }}
                  />
                ))}
            </Carousel>
            <Carousel type="quaternary">
              {data.contentCards &&
                projectList.map((project, index) => (
                  <ProjectCard
                    key={index}
                    background={(isMobile && (typeof project.mobileImage === 'object' && project.mobileImage !== null)) ?project.mobileImage.file.url : project.desktopImage.file.url}
                    text={[project.title]}
                    link={{
                      to: `/projects/${project.ctaDestination.ctaDestination}`,
                      type: "internal",
                    }}
                  />
                ))}
            </Carousel>
          </Column>
        </Row>
      )}
    </Container>
  )
}

export default FeaturedProjects
